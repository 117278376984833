.App {
  text-align: center;
}

.sidebar .active {
  background-color: #dc3545 !important;
}

.navbar .active {
  background-color: #dc3545 !important;
  color: #ffffff !important;
}

.active-size {
  background-color: #212529;
  color: #ffffff;
}

.View {
  position: relative;
}
.main-nav {
  position: fixed;
  z-index: 1000;
  width: 100%;
  top: 0;
}

.View .content {
  margin-top: 3.5rem;
}

.nav-link {
  color: #495057;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.bg-image-cover-center {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.bg-dark-red {
  background-color: rgba(227, 52, 47, 0.5);
  height: 100%;
  width: 100%;
}

.size-auto {
  min-height: 100vh;
}

/* POS Screen */
/* Menu Category Styles */

.menu-category-item {
  border: none;
}

.menu-category-item .item-content {
  padding: 0.5rem;
}

.menu-category-item .item-content .item-name {
  text-align: center;
  font-size: 0.75rem;
  margin-bottom: 0;
  font-weight: 200;
}

.menu-category-item:hover {
  background-color: #FCF0C6;
  cursor: pointer;
}

/* When a Item is activated*/
.menu-category-item.active {
  background-color: #fbdb6b;
  font-weight: 700;
}

.menu-category-item.active .item-content .item-name{
  font-weight: 700;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
